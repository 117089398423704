$(document).ready(function () {
    // Type Ahead    
    $('.js-typeahead-people-search').InitializeTypeAhead({
        initialModelUrl: "/api/sitecore/sitesearch/gettypeaheadmodel",
        serviceUrl: "/api/sitecore/sitesearch/getpeopletypeahead",
        siteSearchUrl: "/professionals/",
        queryKeyword: "keyword"
    });

    $('.js-typeahead-site-search').InitializeTypeAhead({
        initialModelUrl: "/api/sitecore/sitesearch/gettypeaheadmodel",
        serviceUrl: "/api/sitecore/sitesearch/gettypeahead",
        siteSearchUrl: "/site-search/",
        queryKeyword: "query"
    });

    //Binding click event to button that loads what's new
    $('body').on('click', '.menu-trigger-news', function () {
        $.ajax({
            url: "/api/sitecore/navigation/resetlastvisiteddate",
            type: "POST",
            dataType: "json",
            contentType: "application/json; charset=utf-8",
            cache: false,
            success: function (response) {
                $('.news-insight-count').hide();
            },
            error: function (e) {
                console.log('Error contacting server. ' + e);
            }
        });
    });

    $('body').click(function () {
        $('.js-typeahead-people-search .typeahead-results').hide();
    });
});